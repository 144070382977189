var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products__list_content search_product_list"},[_c('form',{staticClass:"app-search d-none d-lg-block",on:{"submit":function($event){$event.preventDefault();return _vm.searchProduct()}}},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control",attrs:{"type":"text","size":"40","placeholder":_vm.$t('navbar.topSearch.text')},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),_c('el-button',{attrs:{"disabled":_vm.searchQuery.length < 2,"icon":"el-icon-search","type":"primary","size":"small","native-type":"submit","circle":""}})],1)]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table b-table",attrs:{"role":"table","aria-busy":"false","aria-colcount":"10"}},[_c('thead',{staticStyle:{"background-color":"#74788d","color":"white"},attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},_vm._l((_vm.columns),function(column){return _c('th',{key:column.key,class:[
                        column.center ? 'text-center': '',
                        'px-4'
                    ],attrs:{"role":"columnheader","scope":"col","aria-colindex":"1"}},[_c('div',[_vm._v(_vm._s(column.value))])])}),0)]),_c('tbody',{attrs:{"role":"rowgroup"}},_vm._l((_vm.results),function(data){return _c('tr',{key:data.id,attrs:{"role":"row","aria-rowindex":"1"}},[_c('td',{staticClass:"col-sm",staticStyle:{"font-size":"14px"},attrs:{"aria-colindex":"1","role":"cell"}},[_c('div',{},[_vm._v(_vm._s(data.id))]),_c('div',{staticClass:"font-weight-bold",staticStyle:{"color":"rgb(0, 136, 204)"}},[_vm._v(" "+_vm._s(data.barcode)+" ")]),_c('el-tooltip',{staticClass:"item mb-1",attrs:{"effect":"dark","content":"BIN#","placement":"left"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#f46a6a"}},[_vm._v(" B "+_vm._s(data.binId == 0 ? "..." : data.binId)+" ")])]),_c('div',{},[(data.AuctionOwner)?_c('span',{staticClass:"badge badge-pill font-size-14 badge-soft-success"},[_vm._v(" "+_vm._s(data.AuctionOwner.displayName)+" ")]):_vm._e()])],1),_c('td',{staticClass:"col-sm",staticStyle:{"font-size":"14px"},attrs:{"aria-colindex":"1","role":"cell"}},[_c('div',{on:{"click":function($event){return _vm.loadProductPictures(data.id, data.AuctionAttachments.length)}}},[(data.AuctionAttachments.length > 0)?_c('v-lazy-image',{staticClass:"d-block w-100 img-thumbnail img-thumbnail-100",attrs:{"src":data.AuctionAttachments[0].thumbnailUrl,"src-placeholder":require('@/assets/images/image-lazy-loading.gif')}}):_c('span',[_vm._v("No Picture available")])],1)]),_c('td',{staticClass:"col-sm",staticStyle:{"font-size":"14px"},attrs:{"aria-colindex":"1","role":"cell"}},[_c('div',{class:[
                            'font-weight-bold',
                            'single-product-title'
                            ]},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('div',{},[(data.parameters.storeCategoryName1)?_c('span',{staticClass:"badge badge-pill font-size-12 badge-soft-danger",staticStyle:{"margin-right":"5px"}},[_vm._v(" "+_vm._s(data.parameters.storeCategoryName1)+" ")]):_vm._e(),(data.parameters.storeCategoryName2)?_c('span',{staticClass:"badge badge-pill font-size-12 badge-soft-danger"},[_vm._v(" "+_vm._s(data.parameters.storeCategoryName2)+" ")]):_vm._e()]),(data.parameters.description)?_c('div',{staticClass:"product-description",staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(data.parameters.description.trim())}}):_vm._e(),_c('div',{staticClass:"font-size-15"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Creation Date:")]),_vm._v(" "+_vm._s(_vm.getFormattedData(data.createdOn))+" ")]),(data.ebayId > 0 )?_c('div',{staticClass:"mt-2 font-size-15"},[_c('strong',[_vm._v("Ebay ID:")]),_c('a',{attrs:{"href":_vm.ebayStoreUrl + '/itm/' + data.ebayId,"target":"_NEW"}},[_vm._v(" "+_vm._s(data.ebayId)+" ")])]):_vm._e(),_c('div',{},[(data.status != 10 || !data.archived)?_c('el-tag',{style:({
                                    color: 'white',
                                    backgroundColor: _vm.getStatus(data.status, 'bgColor'),
                                    borderColor: _vm.getStatus(data.status, 'borderColor')
                                }),attrs:{"type":"success","size":"mini","effect":"dark"}},[_vm._v(" "+_vm._s(_vm.getStatus(data.status, 'text'))+" ")]):_c('el-tag',{style:({
                                    color: 'white',
                                    backgroundColor: _vm.getStatus(10, 'bgColor'),
                                    borderColor: _vm.getStatus(10, 'borderColor')
                                }),attrs:{"type":"success","size":"mini","effect":"dark"}},[_vm._v(" Archived ")])],1)]),_c('td',{class:['text-center', 'px-0', 'col-sm'],attrs:{"aria-colindex":"2","role":"cell"}},[_c('a',{staticClass:"btn btn-warning btn-sm",attrs:{"href":'/products/edit/' + data.id,"target":"_blank"}},[_c('i',{staticClass:"fas fa-edit"})])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }