<script>
import MetisMenu from "metismenujs/dist/metismenujs";

import { menuItems } from "./menu";

import vClickOutside from 'v-click-outside'

/**
 * Sidenav component
 */
export default {
    data() {
        return {
            menuItems: menuItems,
            menuData: null,
        };
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    mounted: function () {
        //allow permission check here
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu("#side-menu");
        var links = document.getElementsByClassName("side-nav-link-ref");
        var matchingMenuItem = null;
        const paths = [];
    
        for (var i = 0; i < links.length; i++) {
            paths.push(links[i]["pathname"]);
        }
        var itemIndex = paths.indexOf(window.location.pathname);
        if (itemIndex === -1) {
            const strIndex = window.location.pathname.lastIndexOf("/");
            const item = window.location.pathname.substr(0, strIndex).toString();
            matchingMenuItem = links[paths.indexOf(item)];
        } else {
            matchingMenuItem = links[itemIndex];
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active");
            var parent = matchingMenuItem.parentElement;

            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.add("mm-active");
                const parent2 = parent.parentElement.closest("ul");
                if (parent2 && parent2.id !== "side-menu") {
                    parent2.classList.add("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add("mm-active");
                        var childAnchor = parent3.querySelector(".has-arrow");
                        var childDropdown = parent3.querySelector(".has-dropdown");
                        if (childAnchor) childAnchor.classList.add("mm-active");
                        if (childDropdown) childDropdown.classList.add("mm-active");

                        const parent4 = parent3.parentElement;
                        if (parent4 && parent4.id !== "side-menu") {
                            parent4.classList.add("mm-show");
                            const parent5 = parent4.parentElement;
                            if (parent5 && parent5.id !== "side-menu") {
                                parent5.classList.add("mm-active");
                                const childanchor = parent5.querySelector(".is-parent");
                                if (childanchor && parent5.id !== "side-menu") {
                                    childanchor.classList.add("mm-active");
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        /**
         * Returns true or false if given menu item has child or not
         * @param item menuItem
         */
        hasItems(item) {
            return item.subItems !== undefined ? item.subItems.length > 0 : false;
        },

        toggleMenu(event) {
            event.currentTarget.nextElementSibling.classList.toggle("mm-show");
        },
        onClickOutside (e) {
            const vertical_menu = document.getElementsByClassName('vertical-menu')[0]
            if(window.getComputedStyle(vertical_menu).display == "block" && e.screenX > 100
                || window.getComputedStyle(vertical_menu).display == "block" && !e.screenX) {
                this.$root.$emit('toggleMenu')
            }
        }
    },
    computed: {
        currentUser () {
            return JSON.parse(localStorage.getItem('user'))
        }
    }
};
</script>

<template>
<!--- Sidemenu -->
<div id="sidebar-menu" v-click-outside="onClickOutside">
    <!-- ========== Left Sidebar Start ========== -->
    <div class="navbar-brand-box" style="display: none">
        <router-link to="/" class="logo logo-dark">
        <span class="logo-sm">
            <img src="@/assets/images/logo.svg" alt height="22" />
        </span>
        <span class="logo-lg">
            <img src="@/assets/images/logo.svg" alt height="17" /> <span class="app-name">CYBERSHOP</span>
        </span>
        </router-link>
        <router-link to="/" class="logo logo-light">
        <span class="logo-sm">
            <img src="@/assets/images/logo-light.svg" alt height="22" />
        </span>
        <span class="logo-lg">
            <img src="@/assets/images/logo-light.svg" alt height="19" /> <span class="app-name">CYBERSHOP</span>
        </span>
        </router-link>
    </div>
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
        <template v-for="item in menuItems">
                <li
                    :key="item.id"
                    class="menu-title"
                    v-if="item.isTitle && currentUser.groups.some(group => item.groups.includes(group))" >
                    {{ $t(item.label) }}
                </li>
                <li :key="item.id" v-if="!item.isTitle && !item.isLayout && currentUser.groups.some(group => item.groups.includes(group))">
                    <a v-if="hasItems(item)" href="javascript:void(0);" class="is-parent" :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }">
                        <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                        <span>{{ $t(item.label) }}</span>
                        <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{ $t(item.badge.text) }}</span>
                    </a>

                    <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
                        <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                        <span>{{ $t(item.label) }}</span>
                        <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{ $t(item.badge.text) }}</span>
                    </router-link>

                    <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                        <li v-for="(subitem, index) of item.subItems" :key="index">
                            <a :href="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref">
                                <span
                                    v-html="subitem.icon"
                                    v-if="subitem.icon">
                                </span>{{ $t(subitem.label) }}
                            </a>
                            <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">{{ $t(subitem.label) }}</a>
                            <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                                <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                                    <a :href="subSubitem.link" class="side-nav-link-ref"><span v-html="subSubitem.icon" v-if="subSubitem.icon"></span>{{ $t(subSubitem.label) }}</a>
                                </li>   
                            </ul>
                        </li>
                    </ul>
                </li>
        </template>
    </ul>
</div>
<!-- Sidebar -->
</template>