<script>
import {
    layoutComputed
} from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import fab from 'vue-fab'

export default {
    components: {
        Vertical,
        Horizontal,
        fab
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
    mounted() {
        const _vm = this
        // document.querySelector("html").setAttribute('dir', 'rtl');
        document.querySelectorAll('.fab-clipboard').forEach( button => {
            button.onclick = function () {
                window.navigator.clipboard.writeText(window.location.href);
                _vm.$toast.success("Copied To Clipboard", {
                    position: "top-right",
                    timeout: 1000,
                    closeOnClick: true
                });
            }
        });
    }
};
</script>

<template>
<div>
    <fab
        class="fab-clipboard"
        position="bottom-left"
        main-icon="assignment"
        bg-color="#f1b44c"
        icon-size="small"
        :ripple-show="false"
        title="Copy Page Link To Clipboard"></fab>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
        <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
        <slot />
    </Horizontal>
</div>
</template>
