<script>
import {  common } from '@/mixins/common';
import moment from 'moment-timezone';
import VLazyImage from "v-lazy-image/v2";

export default {
  name: 'GlobalSearchProductResults',
  components: {
    VLazyImage
  },
  props: {
    results: {
        type: Array,
        required: true
    },
    query: {
        type: String,
        required: true
    }
  },
  data() {
    return {
        pagination: {
            page_size: 50,
            total: 1,
            page: 1
        },
        columns: [
            {
                key: "id",
                value: 'ID',
                sortable: false,
            },
            {
                key: "picture",
                value: 'Pictures',
                sortable: false,
            },
            {
                key: "title",
                value: 'Title',
                sortable: false,
                center: true
            },
            {
                key: "actions",
                value: 'Actions',
                sortable: false,
                center: true
            }
        ],
        searchQuery: ''
    }
  },
  mixins: [
    common
  ],
  methods: {
    searchProduct () {
        this.$root.$emit('searchProduct', this.searchQuery)
    },
    getFormattedData(timestamp) {
      return moment(timestamp*1000).format('YYYY-MM-DD h:mm a')
    },
  },
  computed: {
    ebayStoreUrl () {
      return process.env.NODE_ENV === "development" ? "http://sandbox.ebay.com" : "http://ebay.com"
    }
  },
  mounted () {
    this.searchQuery = this.query
  },
  watch: {
    query: function() {
        this.searchQuery = this.query
    }
  }
}
</script>

<template>
<div class="products__list_content search_product_list">
    <!-- App Search-->
    <form
        class="app-search d-none d-lg-block"
        @submit.prevent="searchProduct()">
        <div class="position-relative">
        <input
            v-model="searchQuery"
            type="text"
            class="form-control"
            size="40"
            :placeholder="$t('navbar.topSearch.text')"
        />
        <el-button
            :disabled="searchQuery.length < 2"
            icon="el-icon-search"
            type="primary"
            size="small"
            native-type="submit"
            circle></el-button>
        </div>
    </form>
    <div class="table-responsive">
        <table
            role="table"
            aria-busy="false"
            aria-colcount="10"
            class="table b-table"
            >
                <thead
                role="rowgroup"
                class=""
                style="background-color: #74788d; color: white"
                >
                <tr role="row" class="">
                    <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    :class="[
                        column.center ? 'text-center': '',
                        'px-4'
                    ]"
                    v-for="column in columns"
                    :key="column.key"
                    >
                    <div>{{ column.value }}</div>
                    </th>
                </tr>
                </thead>
                <tbody role="rowgroup">
                <tr
                    role="row"
                    aria-rowindex="1"
                    class=""
                    v-for="data in results"
                    :key="data.id"
                >
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        <div class="">{{ data.id }}</div>
                        <div
                            class="font-weight-bold"
                            style="color: rgb(0, 136, 204);">
                            {{ data.barcode }}
                        </div>
                        <el-tooltip
                            class="item mb-1"
                            effect="dark"
                            content="BIN#"
                            placement="left">
                            <span class="font-weight-bold" style="color: #f46a6a">
                            B {{ data.binId == 0 ? "..." : data.binId }}
                            </span>
                        </el-tooltip>
                        <div class="">
                            <span
                              class="badge badge-pill font-size-14 badge-soft-success"
                              v-if="data.AuctionOwner"
                            >
                              {{ data.AuctionOwner.displayName }}
                            </span>
                        </div>
                    </td>
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        <div class=""
                            @click="loadProductPictures(data.id, data.AuctionAttachments.length)">
                            <v-lazy-image
                              class="d-block w-100 img-thumbnail img-thumbnail-100"
                              :src="data.AuctionAttachments[0].thumbnailUrl"
                              v-bind:src-placeholder="require('@/assets/images/image-lazy-loading.gif')"
                              v-if="data.AuctionAttachments.length > 0"
                              />
                            <span v-else>No Picture available</span>
                        </div>
                    </td>
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        <div
                            :class="[
                            'font-weight-bold',
                            'single-product-title'
                            ]">
                            {{ data.title }}
                        </div>
                        <div class="">
                            <span
                                class="badge badge-pill font-size-12 badge-soft-danger"
                                style="margin-right: 5px"
                                v-if="data.parameters.storeCategoryName1">
                                {{ data.parameters.storeCategoryName1 }}
                            </span>
                            <span
                                class="badge badge-pill font-size-12 badge-soft-danger"
                                v-if="data.parameters.storeCategoryName2">
                                {{ data.parameters.storeCategoryName2 }}
                            </span>
                        </div>
                        <div
                            class="product-description"
                            v-if="data.parameters.description"
                            v-html="data.parameters.description.trim()"
                            style="white-space: pre-line"
                        ></div>
                        <div class="font-size-15">
                            <span class="font-weight-bold">Creation Date:</span> {{ getFormattedData(data.createdOn) }}
                        </div>
                        <div v-if="data.ebayId > 0 " class="mt-2 font-size-15">
                            <strong>Ebay ID:</strong>
                            <a :href="ebayStoreUrl + '/itm/' + data.ebayId" target="_NEW">
                            {{ data.ebayId }}
                            </a>
                        </div>
                        <div class="">
                            <el-tag
                                type="success"
                                size="mini"
                                effect="dark"
                                :style="{
                                    color: 'white',
                                    backgroundColor: getStatus(data.status, 'bgColor'),
                                    borderColor: getStatus(data.status, 'borderColor')
                                }"
                                v-if="data.status != 10 || !data.archived">
                                {{ getStatus(data.status, 'text') }}
                            </el-tag>
                            <el-tag
                                type="success"
                                size="mini"
                                effect="dark"
                                :style="{
                                    color: 'white',
                                    backgroundColor: getStatus(10, 'bgColor'),
                                    borderColor: getStatus(10, 'borderColor')
                                }"
                                v-else>
                                Archived
                            </el-tag>
                        </div>
                    </td>
                    <td
                        aria-colindex="2"
                        role="cell"
                        :class="['text-center', 'px-0', 'col-sm']">
                        <a
                            :href="'/products/edit/' + data.id"
                            class="btn btn-warning btn-sm"
                            target="_blank">
                            <i class="fas fa-edit"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
    .el-pagination.is-background .el-pager li,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next  {
        border-radius: 20px !important;
        min-width: 34px;
        height: 32px;
        line-height: 34px;
    }
    .img-thumbnail {
        max-width: 110px;
        max-height: 110px;
    }
</style>