<script>
import axios from "axios";
import simplebar from "simplebar-vue";

import i18n from "../i18n";
import { authComputed } from "@/state/helpers";
import { GetApiActionWithAuthorizationOnly } from "../helpers/apiActions";
import GlobalSearchProductResults from "./drawers/product/GlobalSearchProductResults.vue";
import { isMobile, isTablet } from "mobile-device-detect";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      formSearch: {
        query: ''
      },
      displayGlobalSearchProductDrawer: false,
      searchProducts: []
    };
  },
  components: { simplebar, GlobalSearchProductResults },
  mounted() {
    const _vm = this
    _vm.value = _vm.languages.find((x) => x.language === i18n.locale);
    _vm.text = _vm.value.title;
    _vm.flag = _vm.value.flag;
    _vm.$root.$on('toggleMenu', () => {
      _vm.toggleMenu()
    })
    //
    _vm.$root.$on('searchProduct', query => {
      _vm.formSearch.query = query
      _vm.searchProduct()
    })
  },
  methods: {
    goToAppUrl() {
      // eslint-disable-next-line
      console.log("https://apps.cybershop.app/authorize?code=" + localStorage.token)
      window.open("https://apps.cybershop.app/authorize?code=" + localStorage.token, '_blank').focus();
    },
    toggleMenu() {
      this.$parent.toggleMenu();
      const vertical_menu = document.getElementsByClassName('vertical-menu')[0]
      const vertical_menu_brand = document.getElementsByClassName('navbar-brand-box')[0]
      if(window.getComputedStyle(vertical_menu).display == "none") {
        vertical_menu.style.display = "block"
        vertical_menu_brand.style.display = "block"
      } else {
        vertical_menu.style.display = "none"
        vertical_menu_brand.style.display = "none"
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
    },
    logoutUser() {
      // eslint-disable-next-line no-unused-vars
      axios.get("http://127.0.0.1:8000/api/logout").then((res) => {
        this.$router.push({
          name: "default",
        });
      });
    },
    isUserType(type) {
      return this.currentUser.groups.includes(type)
    },
    searchProduct() {
      let _vm = this
      return GetApiActionWithAuthorizationOnly("admin/products/find-products?query=" + _vm.formSearch.query).then(res => {
        _vm.searchProducts = res.data
        _vm.displayGlobalSearchProductDrawer = true
      });
    }
  },
  computed: {
    ...authComputed,
    currentUser () {
      return JSON.parse(localStorage.getItem('user'))
    },
    searchDrawerSize () {
      return isMobile ? '100%' : isTablet ? '80%' : '70%'
    }
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <el-drawer
        ref="globalSearchProductDrawer"
        :title="'Find Products'"
        :visible.sync="displayGlobalSearchProductDrawer"
        :close-on-press-escape="false"
        :with-header="true"
        :wrapperClosable="false"
        :size="searchDrawerSize"
        :append-to-body="true"
        :destroy-on-close="true"
        :direction="'ltr'"
        custom-class="common-drawer demo-drawer find-product-drawer">
        <GlobalSearchProductResults
          :query="formSearch.query"
          :results="searchProducts"/>
      </el-drawer>
      <div class="d-flex">
        <!-- LOGO -->
        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <!-- App Search-->
        <form class="app-search d-none d-lg-block" @submit.prevent="searchProduct()">
          <div class="position-relative">
            <input
              v-model="formSearch.query"
              type="text"
              class="form-control"
              size="40"
              :placeholder="$t('navbar.topSearch.text')"
            />
            <el-button
              :disabled="formSearch.query.length < 2"
              icon="el-icon-search"
              type="primary"
              size="small"
              native-type="submit"
              circle></el-button>
          </div>
        </form>
      </div>

      <div class="d-flex">
        <a
          :href="isUserType('identifier') ? '/identifier/index?tab=new' : '/products/index'"
          class="btn btn-primary btn-sm"
          v-if="currentUser.groups.some(group => ['admin', 'shipper', 'lister', 'identifier'].includes(group))"
        >
          <span class="d-sm-inline-block">
            <i class="fas fa-gem"></i>&nbsp;Products
          </span>
        </a>&nbsp;&nbsp;
        <a
          href="/orders/index"
          class="btn btn-primary btn-sm"
          v-if="currentUser.groups.some(group => ['admin', 'shipper'].includes(group))"
        >
          <span class="d-sm-inline-block">
            <i class="fas fa-shopping-cart"></i>&nbsp;Orders
          </span>
        </a>
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ms-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3" @submit.prevent="searchProduct()">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  v-model="formSearch.query"
                  type="text"
                  class="form-control"
                  :placeholder="$t('navbar.topSearch.text')"
                />
                <div class="input-group-append">&nbsp;
                  <el-button
                    :disabled="formSearch.query.length < 2"
                    icon="mdi mdi-magnify"
                    type="primary"
                    native-type="submit"
                    circle></el-button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>
        <b-dropdown
          class="d-none d-lg-inline-block noti-icon"
          menu-class="dropdown-menu-lg dropdown-menu-end"
          right
          toggle-class="header-item"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-customize"></i>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a
                  class="dropdown-icon-item"
                  href="http://jewelgiant.com/"
                  target="_blank">
                  <img src="https://jewelgiant.com/icon.png" alt="Jewel Giant" />
                  <span>JG Store</span>
                </a>
              </div>
              <div class="col">
                <a
                  class="dropdown-icon-item"
                  href="https://store.nevadacoinmart.com/"
                  target="_blank">
                  <img
                    src="https://store.nevadacoinmart.com/logo.png"
                    alt="bitbucket"
                  />
                  <span>NCM Store</span>
                </a>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col">
                <a
                  class="dropdown-icon-item"
                  href="javascript:;"
                  @click="goToAppUrl()">
                  <img
                    src="@/assets/images/brands/cybershop-app.png"
                    alt="JG Store Manager"
                  />
                  <span>JG Store Manager</span>
                </a>
              </div>
              <div class="col">
                <a
                  class="dropdown-icon-item"
                  href="http://m.cybershop.app"
                  target="_blank">
                  <i class="fas fa-camera"></i>
                  <span>Photo App</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>
        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell"></i>
            <!--i class="bx bx-bell bx-tada"></i>
            <span class="badge bg-danger rounded-pill">{{1}}</span-->
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="#" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px">
            <!--a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.order.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.order.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.order.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a-->
          </simplebar>
          <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              {{ $t("navbar.dropdown.notification.button") }}
            </a>
          </div>
        </b-dropdown>
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-1.png"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ms-1">
              <div v-if="currentUser">
               {{currentUser.first_name}} {{currentUser.last_name}}
              </div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <a href="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
