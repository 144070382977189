// eslint-disable-next-line
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import moment from 'moment-timezone';
import { isMobile, isTablet } from 'mobile-device-detect';
import {
  GetApiActionWithAuthorization,
  PostApiWithAuthorizationAction
} from "@/helpers/apiActions"

export const common = {
    data() {
      return {
        users: [],
        currentUser: {},
        notificationSuccessSound: null,
        triggerField: false
      }
    },

    mounted() {
      let _vm = this
      _vm.triggerField = true
      _vm.$nextTick(() => {
        if (typeof _vm.$refs.drawerPrimaryField !== 'undefined') {
          _vm.$refs.drawerPrimaryField.focus()
        }
        //
        if (typeof _vm.$refs.drawerDescriptionField !== 'undefined') {
          _vm.$refs.drawerDescriptionField.focus()
        }
        //
        if (typeof _vm.$refs.mbBarcodeField !== 'undefined') {
          _vm.$refs.mbBarcodeField.focus()
        }
      })
      //
      _vm.$root.$on('load-comments', (id) => {
        _vm.loadComments(id).then(data => {
          _vm.comments = data
          let productIndex = _vm.tableData.findIndex(v=>v.id==id)
          _vm.tableData[productIndex].Comments = data
        })
      })
      //
      _vm.notificationSuccessSound = new Audio('/sounds/scan-success.mp3');
      //
      _vm.$root.$on('display-bin-items-drawer', id => {
        _vm.formBinNumber = id
        _vm.displayBinItemPopup = true
      })
      //
      _vm.$root.$on('display-bin-drawer', params => {
        for (const property in params) {
          _vm[property] = params[property]
        }
      })
    },

    computed: {
      smallDrawerSize () {
        return isMobile ? '100%' : isTablet ? '50%' : '30%'
      },
      middleDrawerSize () {
        return isMobile ? '100%' : isTablet ? '80%' : '60%'
      },
      defaultPaginationParams () {
        return {
          page: 1,
          pageSize: 50
        }
      },
      loggedUser () {
        return JSON.parse(localStorage.getItem('user'))
      },
      hasMarkReadStatus() {
        return this.formFilter.tab == 'review'
      },
    },

    watch: {
      triggerField () {
        const _vm = this
        // eslint-disable-next-line
        // console.log(_vm.$refs.drawerPrimaryField)
        if (_vm.$refs.drawerPrimaryField) {
          _vm.$refs.drawerPrimaryField.focus()
        }
        //
        if (_vm.$refs.drawerDescriptionField) {
          _vm.$refs.drawerDescriptionField.focus()
        }
        //
        if (typeof _vm.$refs.mbBarcodeField !== 'undefined') {
          _vm.$refs.mbBarcodeField.focus()
        }
        _vm.triggerField = false
      }
    },

    methods: {
      getStatus (status, property) {
        const defaultStatus = {
          color: 'white',
          borderColor: '#909399',
          bgColor: '#909399',
          text: 'Unknow'
        }
        //
        const allStatus = {
          '-2': {
            color: 'white',
            borderColor: '#5d5d5d',
            bgColor: '#5d5d5d',
            text: 'New'
          },
          '-1': {
            color: 'white',
            borderColor: '#909399',
            bgColor: '#909399',
            text: 'Identified'
          },
          '0': {
            color: 'white',
            borderColor: '#909399',
            bgColor: '#909399',
            text: 'Identified'
          },
          '1': {
            color: 'white',
            borderColor: '#409eff',
            bgColor: '#409eff',
            text: 'Review'
          },
          '2': {
            color: 'white',
            borderColor: '#e6a23c',
            bgColor: '#e6a23c',
            text: 'Flagged'
          },
          '3': {
            color: 'white',
            borderColor: '#67c23a',
            bgColor: '#67c23a',
            text: 'Submitted'
          },
          '4': {
            color: 'white',
            borderColor: '#ffc107',
            bgColor: '#ffc107',
            text: 'Queue'
          },
          '6': {
            color: 'white',
            borderColor: '#08c',
            bgColor: '#08c',
            text: 'Sold'
          },
          '7': {
            color: 'white',
            borderColor: '#444444',
            bgColor: '#444444',
            text: 'Unsold'
          },
          '8': {
            color: 'white',
            borderColor: '#00796B',
            bgColor: '#00796B',
            text: 'Shipped'
          },
          '9': {
            color: 'white',
            bgColor: '',
            text: 'Ready'
          },
          '10': {
            color: 'white',
            borderColor: '#f56c6c',
            bgColor: '#f56c6c',
            text: 'Archived'
          },
          '104': {
            color: 'white',
            borderColor: '#888',
            bgColor: '#888',
            text: 'Listing Not found'
          },
        }
        //
        return (typeof allStatus[status] !== 'undefined') ? allStatus[status][property] : defaultStatus[property]
      },
      arraySame (array, target) {
        return target.every(v => array.includes(v))
      },
      playSuccessSound() {
        this.notificationSuccessSound.play()
      },
      formatBinNumber(number) {
        number = (typeof number === 'number') ? number.toString() : number
        return number.padStart(3, '0').replace('000', '...')
      },
      getCommentName(userId) {
        let findUser = Array.isArray(this.users) ? this.users.find(u=>u.id==userId) : false
        return findUser ? findUser.first_name + ' ' + ((findUser.last_name !="") ? findUser.last_name[0] : "") : 'BOT'
      },
      capitalize(string) {
        const arr = string.split(" ");
        //
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join(" ");
      },
      linkify(text) {
        return linkifyHtml(text, {
          target: '_blank',
          ignoreTags: [
            "Item.Quantity"
          ],
          nl2br: true,
          truncate: 80
        })
      },
      truncateString(str, num, ellipsis = '...') {
        if (str.length <= num) {
          return str
        }
        // Return str truncated with ellipsis
        return str.slice(0, num) + ellipsis
      },
      nl2br (str, is_xhtml) {
        // http://kevin.vanzonneveld.net
        // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Philip Peterson
        // +   improved by: Onno Marsman
        // +   improved by: Atli Þór
        // +   bugfixed by: Onno Marsman
        // +      input by: Brett Zamir (http://brett-zamir.me)
        // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Brett Zamir (http://brett-zamir.me)
        // +   improved by: Maximusya
        // *     example 1: nl2br('Kevin\nvan\nZonneveld');
        // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
        // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
        // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
        // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
        // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
      
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
      },
      goToV1Url(url) {
        window.open(process.env.VUE_APP_CYBERSHOP_V1_URL + url, '_blank');
      },
      goToV2Url(url) {
        window.open(process.env.VUE_APP_URL + url, '_blank');
      },
      loadPageSize() {
        return localStorage.getItem('pageSize') ? localStorage.getItem('pageSize') : 50
      },
      loadComments(auctionId)
      {
        return GetApiActionWithAuthorization("admin/products/get-comments/" + auctionId).then(res => {
          return res.data
        });
      },
      loadProfile () {//move into common mixin
        let _vm = this
        GetApiActionWithAuthorization("admin/auth/profile").then(res => {
          if (res.status == "success") {
            _vm.currentUser = res.data
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      getFormattedData(timestamp) {
        return moment(timestamp*1000).format('YYYY-MM-DD h:mm a')
      },
      clearSelection () {
        if (window.getSelection) {
          if (window.getSelection().empty) {  // Chrome
            window.getSelection().empty()
          } else if (window.getSelection().removeAllRanges) {  // Firefox
            window.getSelection().removeAllRanges()
          }
        } else if (document.selection) {  // IE?
          document.selection.empty()
        }
      },
      markReady(auctionId) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/mark-as-read/" + auctionId).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==auctionId)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('ready');
            _vm.updateDomTabCount('review', 1, false)
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      makePreset (auctionId) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/copy-as-preset/" + auctionId).then(res => {
          if (typeof res.url !== 'undefined') {
            _vm.goToV1Url(res.url);
          }
        }).catch(error => {
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      updateDomTabCount(tab = '', count = 1, increase = true) {
        let total = increase
                    ? parseInt(document.getElementById(tab + '-count').innerText) + count
                    : parseInt(document.getElementById(tab + '-count').innerText) - count;
        document.getElementById(tab + '-count').textContent = total
      },
      rebuildPictures(auctionId){
        let _vm = this
        _vm.$toast.success('Rebuilding Picture', {
          position: "bottom-right",
          timeout: false,
          closeOnClick: true,
          icon: 'el-icon-loading'
        });
        GetApiActionWithAuthorization("admin/products/rebuild-pictures/" + auctionId).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==auctionId)
            _vm.tableData[findIndex].AuctionAttachments = res.pictures
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        }).finally(() => {
          _vm.$toast.clear()
        });
      },
      shareToSocialMedia(id) {
        let _vm = this
        _vm.loadSocialMedias().then(() => {
          return GetApiActionWithAuthorization("admin/products/load-full-single-product-data/" + id).then(res => {
            if(res.status == "success") {
              _vm.productDetail = res.data
              _vm.displayShareSocialMediaPopup = true
            }
          })
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      archiveProduct(auctionId) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/archive/" + auctionId).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==auctionId)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('archived');
            _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
            _vm.$toast.error(res.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      sendToFlagged(auctionId) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/send-to-flagged/" + auctionId).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==auctionId)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('flagged');
            _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
            _vm.$toast.success(res.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      markAsIdentified (auctionId) {
        if (confirm('Are you sure that you want to mark this items as Identified ?')) {
          let _vm = this
          GetApiActionWithAuthorization("admin/products/send-to-identified/" + auctionId).then(res => {
            if (res.status == "success") {
              const data = _vm.tableData
              const findIndex = data.findIndex(v=>v.id==auctionId)
              _vm.tableData = data.filter((v, i) => i !== findIndex)
              _vm.updateDomTabCount('identified');
              _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
              _vm.$toast.success(res.message, {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
              });
            }
          }).catch(error => {
            // eslint-disable-next-line
            console.log(error)
            _vm.$toast.error(error.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          });        
        }
      },
      sendToReview(auctionId) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/send-to-review/" + auctionId).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==auctionId)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('review');
            _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
            _vm.$toast.success(res.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      readyRevert (id) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/ready-revert/" + id).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==id)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('ready', 1, false)
            _vm.updateDomTabCount('review');
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      forceSubmit (id) {
        if (confirm('Are you sure that you want to list this item by force ?')) {
          let _vm = this
          _vm.$toast.warning('Force Submitting Product To Ebay...', {
            position: "bottom-right",
            timeout: false,
            closeOnClick: true,
            icon: 'el-icon-loading'
          })
          PostApiWithAuthorizationAction("admin/products/force-submit-single/" + id).then(res => {
            _vm.$toast.clear()
            if (res.status == "success") {
              const data = _vm.tableData
              const findIndex = data.findIndex(v=>v.id==id)
              _vm.tableData = data.filter((v, i) => i !== findIndex)
              _vm.updateDomTabCount('submitted');
              _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
              _vm.$toast.success(res.message, {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
              });
            }
          }).catch(error => {
            // eslint-disable-next-line
            console.log(error)
            _vm.$toast.clear()
            _vm.$toast.error(error.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }).finally(() => {
            _vm.$root.$emit('load-comments', id)
          });
        }
      },
      reviseItem (id) {
        let _vm = this
        _vm.$toast.warning('Updating Product Information in Ebay...', {
          position: "bottom-right",
          timeout: false,
          closeOnClick: true,
          icon: 'el-icon-loading'
        })
        PostApiWithAuthorizationAction("admin/products/revise-item/" + id).then(res => {
          _vm.$toast.clear()
          if (res.status == "success") {
            _vm.$toast.success(res.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.clear()
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true
          });
        })
      },
      callToQueue (id) {
        let _vm = this
        _vm.$toast.warning('Calling back to Queue...', {
          position: "bottom-right",
          timeout: false,
          closeOnClick: true,
          icon: 'el-icon-loading'
        })
        PostApiWithAuthorizationAction("admin/products/recall-to-queue/" + id).then(res => {
          _vm.$toast.clear()
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==id)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('queue');
            _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
            _vm.$toast.success(res.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.clear()
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true
          });
        })
      },
      endListing (id) {
        if (confirm('Are you sure that you want to end this listing ?')) {
          let _vm = this
          _vm.$toast.warning('Ending Listing...', {
            position: "bottom-right",
            timeout: false,
            closeOnClick: true,
            icon: 'el-icon-loading'
          })
          PostApiWithAuthorizationAction("admin/products/end-item/" + id).then(res => {
            _vm.$toast.clear()
            if (res.status == "success") {
              const data = _vm.tableData
              const findIndex = data.findIndex(v=>v.id==id)
              _vm.tableData = data.filter((v, i) => i !== findIndex)
              _vm.updateDomTabCount('unsold');
              _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
              _vm.$toast.success(res.message, {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
              });
            }
          }).catch(error => {
            // eslint-disable-next-line
            console.log(error)
            _vm.$toast.clear()
            _vm.$toast.error(error.message, {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true
            });
          })
        }
      },
      relistItem (id) {
        if (confirm('Are you sure that you want relist this item ?')) {
          let _vm = this
          _vm.$toast.warning('Relisting...', {
            position: "bottom-right",
            timeout: false,
            closeOnClick: true,
            icon: 'el-icon-loading'
          })
          PostApiWithAuthorizationAction("admin/products/relist-item/" + id).then(res => {
            _vm.$toast.clear()
            if (res.status == "success") {
              const data = _vm.tableData
              const findIndex = data.findIndex(v=>v.id==id)
              _vm.tableData = data.filter((v, i) => i !== findIndex)
              _vm.updateDomTabCount('submitted');
              _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
              _vm.$toast.warning(res.message, {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
              });
            }
          }).catch(error => {
            // eslint-disable-next-line
            console.log(error)
            _vm.$toast.clear()
            _vm.$toast.error(error.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          });
        }
      },
      sendToRelist (id) {
        if (confirm('Are you sure that you want to move this item to relist ?')) {
          let _vm = this
          GetApiActionWithAuthorization("admin/products/send-to-relist/" + id).then(res => {
            if (res.status == "success") {
              const data = _vm.tableData
              const findIndex = data.findIndex(v=>v.id==id)
              _vm.tableData = data.filter((v, i) => i !== findIndex)
              _vm.updateDomTabCount('unsold');
              _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
              _vm.$toast.warning(res.message, {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
              });
            }
          }).catch(error => {
            // eslint-disable-next-line
            console.log(error)
            _vm.$toast.error(error.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          });
        }
      },
      submitToQueue (id) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/submit-single/" + id).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==id)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('queue');
            _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
            _vm.$toast.warning(res.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      },
      getUrlParams (searchParams = null) {
        const parser = new URL(window.location)
        if (!searchParams) {
          searchParams = parser.searchParams
        }
        let params = {}
        for (const [key, value] of searchParams.entries()) {
          params[key] = value
        }
        return params
      },
      getFormattedDateFromFull(date, format = 'YYYY-MM-DD HH:mm:ss') {
        return moment(date).format(format)
      },
      getDateTimeAgo(date) {
        return moment(date).fromNow()
      }
    },
}