export const menuItems = [
    {
        id: 1,
        label: "menuitems.messages.text",
        groups: [
            'admin'
        ],
        icon: "bx-chat",
        subItems: [
            {
                id: 3,
                label: "menuitems.messages.list.main",
                icon: '<i class="fas fa-inbox"></i>',
                link: "/mail/inbox",
                parentId: 2
            },
            {
                id: 4,
                label: "menuitems.messages.list.order",
                icon: '<i class="fas fa-shopping-basket"></i>',
                link: "/mail/order",
                parentId: 2
            },
            {
                id: 5,
                label: "menuitems.messages.list.offer",
                icon: '<i class="fas fa-bullhorn"></i>',
                link: "/mail/offer",
                parentId: 2
            },
            {
                id: 6,
                label: "menuitems.messages.list.question",
                icon: '<i class="fas fa-question"></i>',
                link: "/mail/question",
                parentId: 2
            }
        ],
        link: "/mail",
    },
    {
        id: 2,
        label: "menuitems.orders.text",
        groups: [
            'admin',
            'shipper'
        ],
        icon: "bx-cart",
        subItems: [
            {
                id: 3,
                label: "menuitems.orders.list.ready_to_ship",
                link: "/orders/index",
                parentId: 2
            },
            {
                id: 4,
                label: "menuitems.orders.list.completed",
                link: "/orders/index?tab=completed",
                parentId: 2
            },
            {
                id: 5,
                label: "menuitems.orders.list.flagged",
                link: "/orders/index?tab=flagged",
                parentId: 2
            },
            {
                id: 6,
                label: "menuitems.orders.list.unpaid",
                link: "/orders/index?tab=unpaid",
                parentId: 2
            },
            {
                id: 7,
                label: "menuitems.orders.list.cancelled",
                link: "/orders/index?tab=cancelled",
                parentId: 2
            }
        ]
    },
    {
        id: 3,
        label: "menuitems.stats.text",
        groups: [
            'admin',
            'lister',
            'identifier'
        ],
        icon: "bx-stats",
        subItems: [
            {
                id: 8,
                label: "menuitems.stats.list.submission",
                link: "/stats/default",
                parentId: 3
            },
            {
                id: 9,
                label: "menuitems.stats.list.identifiers",
                link: "/stats/identifiers",
                parentId: 3
            },
            {
                id: 10,
                label: "menuitems.stats.list.photographers",
                link: "/stats/photographers",
                parentId: 3
            },
            {
                id: 11,
                label: "menuitems.stats.list.listers",
                link: "/stats/listers",
                parentId: 3
            },
            {
                id: 12,
                label: "menuitems.stats.list.earnings",
                link: "/stats/earnings",
                parentId: 3
            },
            {
                id: 13,
                label: "menuitems.stats.list.submitted",
                link: "/stats/submitted",
                parentId: 3
            },
            {
                id: 14,
                label: "menuitems.stats.list.page_posts",
                link: "/stats/page_posts",
                parentId: 3
            },
            {
                id: 14,
                label: "menuitems.stats.list.sold_items",
                link: "/stats/sold_items",
                parentId: 3
            }
        ]
    },
    {
        id: 15,
        label: "menuitems.barcode.text",
        groups: [
            'admin',
            'shipper'
        ],
        icon: "bx-barcode",
        subItems: [
            {
                id: 16,
                label: "menuitems.barcode.list.barcode",
                link: "/barcode/index",
                parentId: 15
            },
            {
                id: 17,
                label: "menuitems.barcode.list.bin",
                link: "/barcode/bins",
                parentId: 15
            },
        ]
    },
    {
        id: 18,
        label: "menuitems.main_setting.text",
        groups: [
            'admin'
        ],
        icon: "bx-wrench",
        subItems: [
            {
                id: 19,
                label: "menuitems.main_setting.list.general",
                link: "/settings/general",
                parentId: 18
            },
            {
                id: 20,
                label: "menuitems.main_setting.list.presets",
                link: "/settings/presets",
                parentId: 18
            },
            {
                id: 21,
                label: "menuitems.main_setting.list.applications",
                link: "/settings/applications",
                parentId: 18
            },
            {
                id: 22,
                label: "menuitems.main_setting.list.users",
                link: "/settings/users",
                parentId: 18
            },
            {
                id: 23,
                label: "menuitems.main_setting.list.daily_post",
                link: "/settings/daily_post",
                parentId: 18
            }
        ]
    },
    {
        id: 24,
        label: "menuitems.secondary_setting.text",
        groups: [
            'admin'
        ],
        icon: "bx-wrench",
        subItems: [
            {
                id: 25,
                label: "menuitems.secondary_setting.list.owners",
                link: "/settings/owners",
                parentId: 24
            },
            {
                id: 26,
                label: "menuitems.secondary_setting.list.product_tags",
                link: "/settings/product_tags",
                parentId: 24
            },
            {
                id: 27,
                label: "menuitems.secondary_setting.list.flagged",
                link: "/settings/flagged_keywords",
                parentId: 24
            },
            {
                id: 28,
                label: "menuitems.secondary_setting.list.shipping",
                link: "/settings/shipping",
                parentId: 24
            }
        ]
    },
    {
        id: 30,
        label: "menuitems.advanced_setting.text",
        groups: [
            'admin'
        ],
        icon: "bx-wrench",
        subItems: [
            {
                id: 31,
                label: "menuitems.advanced_setting.list.social_medias",
                link: "/settings/social_medias",
                parentId: 30
            },
            {
                id: 32,
                label: "menuitems.advanced_setting.list.ip_whitelist",
                link: "/settings/ip-whitelist",
                parentId: 30
            },
            {
                id: 33,
                label: "menuitems.advanced_setting.list.item_specifics",
                link: "/settings/item_specifics",
                parentId: 30
            },
            {
                id: 34,
                label: "menuitems.advanced_setting.list.store_categories",
                link: "/settings/store_categories",
                parentId: 30
            },
            {
                id: 35,
                label: "menuitems.advanced_setting.list.modes",
                link: "/settings/modes",
                parentId: 30
            }
        ]
    },
    {
        id: 36,
        label: "menuitems.thirdPartyApp.text",
        groups: [
            'admin'
        ],
        icon: "bx-cube-alt",
        subItems: [
            {
                id: 37,
                label: "menuitems.thirdPartyApp.list.customers",
                link: "https://apps.cybershop.app/customers",
                external: true,
                parentId: 36
            },
            {
                id: 38,
                label: "menuitems.thirdPartyApp.list.crawler",
                link: "https://apps.cybershop.app/duplicate-crawler",
                external: true,
                parentId: 36
            },
            {
                id: 39,
                label: "menuitems.thirdPartyApp.list.central_db",
                link: "javascript:;",
                parentId: 36
            },
        ]
    }
];